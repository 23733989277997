if (typeof EveryScape === 'undefined' || EveryScape === null) {
	EveryScape = {};
}

EveryScape.Events = {
	CreateManager: function (spoolOnStart) {
		var events = {};
		var eventsAny = [];
		var eventDocs = {};
		var once = {};
		var spooling = spoolOnStart ? true : false;
		var spool = [];
		var isValidEvent = function (value) {
			isValid = true;
			if (typeof value !== 'string') {
				if (Array.isArray(value)) {
					value.forEach(function (v) {
						if (typeof v !== 'string') {
							isValid = false;
						}
					})
				} else {
					isValid = false;
				}
			}
			return isValid;
		}

		var doEmit = function (event, args) {
			// Handle the case where (as during debugging) somebody wants to hook into every event
			for (var i = 0; i < eventsAny.length; i++) {
				// Send to each subscriber a new args array, prepending the event name as the first element.
				eventsAny[i].apply(eventsAny[i], [event].concat(args));
			}

			event = event.toLowerCase();
			// One-time subscribers
			if (once[event] && once[event].length > 0) {
				while (once[event].length > 0) {
					var e = once[event].shift();
					e.apply(e, args);
				}
			}

			// Just the regular subscribers now.
			if (events[event]) {

				// Call each listener in the order they were registered.
				for (var i = 0; i < events[event].length; i++) {
					let callback = events[event][i];
					setTimeout(function () {
						callback.apply(callback, args);
					}, 0);
				}
			}
		}

		/**
		 * @class
		 * @alias EveryScape.EventsManager
		 * @typedef EveryScape.EventsManager
		 * */
		var eventsContext = {
			/**
			 * Any occurance of the given event or events will trigger the listener
			 * @param {(string\string[])} event
			 * @param {function} listener
			 */
			on: function (event, listener) {
				if (!isValidEvent(event)) throw new TypeError("Event must be a string or array of strings");
				if (typeof listener !== 'function') throw new TypeError("Listener must be a function");

				if (!Array.isArray(event)) {
					event = [event];
				}

				event.forEach(function (e) {
					e = e.toLowerCase();
					events[e] || (events[e] = []);
					events[e].push(listener);
				});
			},

			/**
			 * Any occurance of any event will trigger the listener. Can be useful for debugging event calls.
			 * @param {function} listener
			 */
			onAny: function (listener) {
				if (typeof listener !== 'function') throw new TypeError("Listener must be a function");
				eventsAny.push(listener);
			},

			/**
			 * Only the next occurance of the given event or events will trigger the listener.
			 * @param {(string\string[])} event
			 * @param {function} listener
			 */
			once: function (event, listener) {
				if (!isValidEvent(event)) throw new TypeError("Event must be a string or array of strings");
				if (typeof listener !== 'function') throw new TypeError("Listener must be a function");

				if (!Array.isArray(event)) {
					event = [event];
				}

				// Since "once" automatically removes itself after a single execution, we need to
				// insert a shim to do that for all of them when any of the events in the list happen.
				var unifiedCallback = function () {
					event.forEach(function (e) {
						e = e.toLowerCase();
						eventsContext.off(e, unifiedCallback);
					});

					listener.apply(listener, Array.prototype.slice.call(arguments, 1));
				}

				event.forEach(function (e) {
					e = e.toLowerCase();
					once[e] || (once[e] = []);
					once[e].push(unifiedCallback);
				});
			},

			/**
			 * Stop reporting the given event or events to the particular listener provided.
			 * @param {(string\string[])} event
			 * @param {function} listener
			 */
			off: function (event, listener) {
				if (isValidEvent(event) && typeof listener === 'function') {
					if (!Array.isArray(event)) { event = [event]; };
					event.forEach(function (e) {
						e = e.toLowerCase();
						if (events[e]) {
							events[e] = events[e].filter(function (f) {
								return f !== listener;
							});
						}
						if (once[e]) {
							once[e] = once[e].filter(function (f) {
								return f !== listener;
							});
						}
					});
				}
			},

			/**
			 * Stop reporting events to the particular listener that had previously been set via .onAny(listener)
			 * Does not stop events that have been explicitly requested to use the same listener.
			 * @param {function} listener
			 */
			offAny: function (listener) {
				if (listener) {
					eventsAny = eventsAny.filter(function (f) {
						return f !== listener;
					});
				}
			},

			/**
			 * Unlink all listeners so that they (and this) can be released from memory
			 */
			clear: function () {
				Object.keys(events).forEach(label => {
					if (Array.isArray(events[label])) {
						events[label] = null;
					}
				});
				events = {};
				spool = [];
			},

			/**
			 * Report an occurance of an event to appropriate listeners, or add to the queue if spooling is enabled.
			 * @param {string} event
			 */
			emit: function (event) {
				// Get the relevant arguments
				var eventArgs = Array.prototype.slice.call(arguments, 1);

				if (spooling) {
					spool.push({ event: event, args: eventArgs });
				} else {
					doEmit(event, eventArgs);
				}
			},

			/**
			 * Report an occurance of an event to appropriate listeners right now, regardless of spooling status.
			 * Useful during application bootstrapping.
			 * @param {string} event
			 */
			emitNow: function (event) {
				// Get the relevant arguments
				var eventArgs = Array.prototype.slice.call(arguments, 1);
				if (!event && eventArgs.length > 0) {
					event = eventArgs.shift();
				}
				doEmit(event, eventArgs);
			},

			/**
			 * 
			 * */
			spooler: {
				disable: function () {
					spooling = false;
				},
				enable: function () {
					spooling = true;
				},
				flush: function () {
					while ((evt = spool.shift()) !== undefined) {
						if (evt.event) {
							evt.args = evt.args !== undefined ? evt.args : [];
							doEmit(evt.event, evt.args);
						}
					};
				},
				spool: spool
			},

			/** 
			 *  Privide a means for the application consuming this library to document in memory which events are in use.
			 *  Useful for members of a team doing discovery in a debugger.
			 *  @alias documentation
			 *  @namespace 
			 */
			documentation: {
				add: function (event, description) {
					eventDocs[event] = description;
				},
				getDescription: function (event) {
					return eventDocs[event];
				},
				getList: function () {
					return Object.keys(eventDocs);
				}
			},

			/**
			 * 
			 * */
			debounce: EveryScape.Events.Debounce,

			/**
			 * 
			 * */
			throttle: EveryScape.Events.Throttle

		}
		return eventsContext;
	},
	/**
	 * Creates a property to which functions may be assigned that will be executed when the given event occurs
	 * in the given event manager's context.
	 * @param {any} obj
	 * @param {string} propertyName
	 * @param {EveryScape.EventsManager} eventContext
	 * @param {string} event
	 * @example
	 *		// Setup:
	 *		var sourceObject = {};
	 *		var targetObject = Foo();
	 *		sourceObject.eventsContext = EveryScape.Events.CreateManager();
	 *		EveryScape.Events.DefineEventHandler(targetObject, 'onBarEvent', sourceObject.eventsContext, 'BarEvent');
	 *		
	 *		// Usage:
	 *		targetObject.onBarEvent = function(baz) { console.log('BarEvent happened with argument: ' + baz); };
	 *		sourceObject.eventsContext.emit('BarEvent', 'biz!');
	 *		
	 *		>> BarEvent happened with argument: biz!
	 */
	DefineEventHandler: function (obj, propertyName, eventContext, event) {
		if (!obj[propertyName]) {
			Object.defineProperty(obj, propertyName, {
				set: function (listener) {
					if (!listener.off) {
						listener.off = function (evt) {
							eventContext.off(evt, listener)
						}
					}
					eventContext.on(event, listener);
				}
			});
		}
	},

	/**
	 * Puts a debounce layer to 
	 * @param {any} listener
	 * @param {any} limit
	 */
	Debounce: function (listener, limit) {
		let currentDebounce;
		return function () {
			const context = this;
			const args = arguments;
			clearTimeout(currentDebounce);
			currentDebounce = setTimeout(function () { listener.apply(context, args); }, limit);
		}
	},

	/**
	 * Puts a throttling layer to define how often a listener should actually be called.
	 * 
	 * In the case where the listener is called multiple times during a throttle period, only
	 * the first and last invocations will be executed, and the last one at the end of the period.
	 * @param {function} listener
	 * @param {number} limit (in ms)
	 * 
	 *		// Usage:
	 *		var events = EveryScape.Events.CreateManager();
	 *		var listener = function() { console.log('Event Happened'); }
	 *		events.on("EventLabel", EveryScape.Events.Throttle(listener, 500));
	 *		
	 *		// Usage (cont.) A shorter form also exists within the EventsManager instance:
	 *		var events = EveryScape.Events.CreateManager();
	 *		var listener = function() { console.log('Event Happened'); }
	 *		events.on("EventLabel", events.throttle(listener, 500));
	 */
	Throttle: function (listener, limit) {
		let lastCall;
		let lastRuntime;
		return function () {
			const context = this;
			const args = arguments;
			if (!lastRuntime) {
				listener.apply(context, args);
				lastRuntime = Date.now();
			} else {
				clearTimeout(lastCall);
				lastCall = setTimeout(function () {
					if ((Date.now() - lastRuntime) >= limit) {
						listener.apply(context, args);
						lastRuntime = Date.now();
					}
				}, limit - (Date.now() - lastRuntime));
			}
		}
	}
};

