if ('undefined' === typeof(EveryScape)) { EveryScape = {}; }
if (!EveryScape.SyncV2) { EveryScape.SyncV2 = {}; }

//
// Abstractions for code I don't want to bring into this project.
// TODO: The floor plan viewer should be completely separated from page business logic and syncv2 model data and have the following functions:
// - loadFloorPlan(floor plan url, set of markers)
// - setRadars(all the radars for other users and current user)
// - onSelectMarker(contentPath) -> hooked by the controller to updated the viewer.
//
EveryScape.SyncV2.FloorPlanViewerUtils = {
	guid: function () { return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); return v.toString(16); }); }
};
esv2ui = {
	// EveryScape.SyncV2.UI.showFloorPlanInWidget();
	showFloorPlanInWidget: function () {
		$("#FloorPlanContainer").addClass("active");
		$("#FloorPlanContainer").show();
	},
	// EveryScape.SyncV2.UI.FloorPlan.events.emit("Shown");
	floorPlanShown: function () {
	}
};
class FloorPlanModel {
	constructor() {
		this.clientInstanceId = null;
		this.mapTelemetryModel = null;
		const me = this;
		this.clientInstances = {
			get: function (clientInstanceId) {
				return me._getClientInstance(clientInstanceId);
			},
			list: function () {
				const result = [];
				if (!this.mapTelemetryModel) {
					return result;
				}
				for (const cid in this.mapTelemetryModel.clientInstances) {
					result.push(me._getClientInstance(cid));
				}
				return result;
			}
		}
	}
	get self() {
		return { clientInstanceId: this.clientInstanceId ? this.clientInstanceId : 'self' }
	}
	get telemetrySelf() {
		return this._getClientInstance(this.clientInstanceId);
	}
	_getClientInstance(clientInstanceId) {
		return this.mapTelemetryModel ? this.mapTelemetryModel.getClientInstance(clientInstanceId) : { clientInstanceId: clientInstanceId, connected: false };
	}
}
esv2CurrentState = new FloorPlanModel();


EveryScape.SyncV2.FloorPlanViewer = function (config) {
	// Initialize Events Instance
	var events = EveryScape.Events.CreateManager();


	// Define Enumerations
	var enums = {
		uiStatuses: {
			floorPlan: 1,
			loading: 2,
			none: 3
		}
	}


	// Initialize State
	var state = {
		clients: {
			markers: new Map(), // [type: EveryScape.SyncV2.FloorPlanViewer.ClientMarker]
			locks: {}
		},
		configuration: {
			allowDiscovery: true,
			applicationState: null,
			container: null,
		},
		contentMarkers: new Map(),
		dom: {
			floorPlanContainer: null,
			loadingAnimation: null,
			noFloorPlan: null,
			outerContainer: null
		},
		dirty: true,
		floorPlans: {
			awaiting: null,
			collection: new Map(), // [type: EveryScape.SyncV2.FloorPlanViewer.Descriptor]
			currentFloorPlanId: null,
			locationIndex: new Map() // [type: EveryScape.SyncV2.FloorPlanViewer.Descriptor]
		},
		isLoaded: false,
		leaflet: {
			defaultZoom: null,
			instance: null,
			layers: {
				markers: null,
				overlay: null
			},
			maxBounds: [[], []]
		},
		renderLock: null,
		uiStatus: 1,
		uiStatusTimeoutHandle: null
	};


	// Define local methods
	var local = {
		addContentMarker: function (x, y, name, contentPath, floorPlanHeading, displayIcon) {
			var currentDescriptor = state.floorPlans.collection.get(state.floorPlans.currentFloorPlanId);
			var hasPosition = false;
			if (x === null || y === null) {
				x = null;
				y = null;
			} else {
				y = currentDescriptor.naturalHeight - y; // CRS.Simple has an origin of bottom-left, and we want top-left.
				hasPosition = true;
			}
			var contentMarker = EveryScape.SyncV2.FloorPlanViewer.ContentMarker(contentPath, name, x, y, floorPlanHeading, displayIcon);
			state.contentMarkers.set(contentPath, contentMarker);
			if (hasPosition) {
				contentMarker.getMarker().addTo(state.leaflet.layers.markers);
				contentMarker.events.on('Selected', function () {
					events.emit('ContentSelected', contentMarker.contentPath);
				});
			}
			return contentMarker;
		},
		addFloorPlanDescriptor: function (descriptor, replace) {
			return new Promise(function (resolve, reject) {
				if (!descriptor || !descriptor.id) {
				} else {
					var existing = state.floorPlans.collection.get(descriptor.id);
					if (!existing || replace) {
						state.floorPlans.collection.set(descriptor.id, descriptor);
						descriptor.getPositions().forEach(function (position) {
							var list = state.floorPlans.locationIndex.get(position.contentPath);
							if (list) {
								list.push(descriptor.id);
							} else {
								list = [descriptor.id];
							}
							state.floorPlans.locationIndex.set(position.contentPath, list);
						});
					}
				}
				return resolve(descriptor.id);
			});
		},
		addFloorPlanDescriptors: function (descriptorList) {
			var promises = [];
			descriptorList.forEach(function (descriptor) {
				promises.push(local.addFloorPlanDescriptor(descriptor));
			});
			return Promise.all(promises);
		},
		centerOnClient: function (clientId, animate) {
			return new Promise(function (resolve, reject) {
				var client = state.clients.markers.get(clientId);
				if (client && client.contentPath) {
					local.centerOnLocation(client.contentPath, animate)
						.then(resolve)
						.catch(reject);
				} else {
					events.emit('ClientNotFound');
					resolve();
				}
			});
		},
		centerOnLocation: function (contentPath, animate) {
			return new Promise(function (resolve, reject) {
				var doCenter = function () {
					var marker = state.contentMarkers.get(contentPath);
					var destLatLng = marker.getMarker().getLatLng();
					if (destLatLng) {
						var currentZoom = state.leaflet.instance.getZoom();
						state.leaflet.instance.setView(destLatLng, currentZoom, { animation: animate });
					} else {
						destLatLng = state.leaflet.maxBounds.getCenter();
						state.leaflet.instance.setView(destLatLng, state.leaflet.defaultZoom, { animation: animate });
					}


					events.emit('CenteredOnContent', contentPath);
					resolve(destLatLng);
				}

				var currentDescriptor = state.floorPlans.collection.get(state.floorPlans.currentFloorPlanId);

				if (currentDescriptor && currentDescriptor.getPosition(contentPath)) {
					// The requested contentPath is represented on the current floor plan - we'll stick with that then.
					doCenter();
				} else {
					// We need to find the right floorPlanId for the location we're trying to center on.
					local.getDescriptorsByContentPath(contentPath).then(function (list) {
						if (list.length > 0) {
							// No special magic here - first one in the list gets our vote. Maybe we can add weights in the future.
							local.selectFloorPlan(list[0]).then(doCenter);
							// TODO: Remove Below from this js file
							esv2ui.showFloorPlanInWidget();
						} else {
							events.emit("DescriptorNotFound", contentPath);
							resolve();
						}
					});
				}
			});
		},
		generateFloorPlan: function (floorPlanId) {
			return new Promise(function (resolve, reject) {
				// Get the relevant floor plan descriptor
				var descriptor = state.floorPlans.collection.get(floorPlanId);
				if (descriptor && descriptor.imageUrl && descriptor.naturalHeight && descriptor.naturalWidth) {
					// Show loading animation
					$(state.dom.floorPlanContainer).show();
					local.setUIStatus(enums.uiStatuses.loading);

					window.requestAnimationFrame(function () {
						state.floorPlans.currentFloorPlanId = floorPlanId;

						var instance = state.leaflet.instance;

						// Remove the old image.
						if (state.leaflet.layers.overlay) {
							instance.removeLayer(state.leaflet.layers.overlay);
						}
						// Clear the markers layer and collection
						if (state.leaflet.layers.markers) {
							state.leaflet.layers.markers.clearLayers();
						}
						state.contentMarkers.clear();

						// Determine the correct boundaries for the new image, and attach it as an overlay
						state.leaflet.maxBounds = new L.LatLngBounds([0, 0], [descriptor.naturalHeight, descriptor.naturalWidth]);
						state.leaflet.instance.setMaxBounds(state.leaflet.maxBounds);
						state.leaflet.layers.overlay = L.imageOverlay(descriptor.imageUrl, state.leaflet.maxBounds)
							.addTo(state.leaflet.instance);

						descriptor.getPositions().forEach(function (position) {
							local.addContentMarker(position.x, position.y, position.name, position.contentPath, position.heading, position.displayIcon);
						});

						instance.invalidateSize();
						local.setInstanceGeometry(descriptor);

						local.setUIStatus(enums.uiStatuses.floorPlan)
							.then(local.renderFloorPlan)
							.then(function () {
								resolve(floorPlanId);
							});
					});
				} else {
					return reject('generateFloorPlan: Invalid floorPlan requested.');
				}
			});
		},
		getDescriptorsByContentPath: function (contentPath) {
			var list = state.floorPlans.locationIndex.get(contentPath);
			if (!list) { list = []; };
			return Promise.resolve(list);
		},
		hasFloorPlanDescriptor: function (floorPlanId) {
			return Promise.resolve(state.floorPlans.collection.has(floorPlanId));
		},
		renderFloorPlan: function () {
			return new Promise(function (resolve, reject) {
				// Assumes that the correct floor plan itself is already loaded (local.selectFloorPlan has resolved)
				if (!state.renderLock || Date.now() > state.renderLock + 3000) {
					state.renderLock = Date.now();

					var appState = state.configuration.applicationState;

					// Set up independent loops instead of nested loops, so this is more efficient.
					var contentClientMap = new Map();
					state.clients.markers.forEach(function (client, clientInstanceId) {
						var list = contentClientMap.get(client.contentPath);
						var clientInstance = null;
						if (appState.telemetrySelf.clientInstanceId == clientInstanceId) {
							clientInstance = appState.telemetrySelf;
						} else {
							clientInstance = appState.clientInstances.get(clientInstanceId);
						}
						if (clientInstance && clientInstance.connected) {
							if (list) {
								list.push(client);
							} else {
								list = [client];
							}
							contentClientMap.set(client.contentPath, list);
						}
					});

					window.requestAnimationFrame(function () {
						state.contentMarkers.forEach(function (contentMarker) {
							var $clientsDiv = jQuery(contentMarker.getClientsDiv());
							if ($clientsDiv) {
								$clientsDiv.empty();
								contentMarker.clients = {};

								var toPlace = contentClientMap.get(contentMarker.contentPath);
								if (toPlace) {
									toPlace.forEach(function (client) {
										if (client && client.getDiv) {
											contentMarker.clients[client.clientInstanceId] = client;
											$clientsDiv.append(client.getDiv());
											if (client.clientInstanceId == appState.telemetrySelf.clientInstanceId) {
												client.setHeading(appState.telemetrySelf.heading);
											} else {
												client.setHeading(appState.clientInstances.get(client.clientInstanceId).heading);
											}
										}
									});
								}
								contentMarker.updateOverlay();
							}
						});
						state.renderLock = null;
						return resolve(); // Rendered.
					});
				} else {
					return resolve(); // Nothing to do.
				}
			});
		},
		selectFloorPlan: function (floorPlanId) {
			if (floorPlanId && floorPlanId == state.floorPlans.currentFloorPlanId) {
				events.emit('FloorPlanSelected', floorPlanId);
				return Promise.resolve(floorPlanId);
			} else {
				return new Promise(function (resolve, reject) {
					local.generateFloorPlan(floorPlanId)
						.then(function (returnValue) {
							events.emit('FloorPlanSelected', returnValue);
							resolve(returnValue);
						})
						.catch(function (e) {
							reject(e);
						});
				});
			}
		},
		setInstanceGeometry: function (descriptor) {
			return new Promise(function (resolve, reject) {
				if (!descriptor) {
					descriptor = state.floorPlans.collection.get(state.floorPlans.currentFloorPlanId);
				}
				try {
					if (descriptor) {
						// Sort out the display parameters for this image
						var viewportDimensions = state.dom.floorPlanContainer.getBoundingClientRect();
						var ratioX = descriptor.naturalWidth / viewportDimensions.width;
						var ratioY = descriptor.naturalHeight / viewportDimensions.height;
						var further = ratioX > ratioY ? ratioX : ratioY;
						var power = Math.ceil(Math.log(further) / Math.log(2));
						var minimumZoom = -power;
						var maxZoom = 2;
						//state.leaflet.defaultZoom = Math.round((minimumZoom + minimumZoom + maxZoom) / 3);
						let dz = minimumZoom + 1;
						state.leaflet.defaultZoom = dz > maxZoom ? maxZoom : dz;

						// Set Zoom parameters
						state.leaflet.instance.setMinZoom(minimumZoom);
						state.leaflet.instance.setMaxZoom(maxZoom);
						var toZoom = state.leaflet.instance.getZoom();
						if (Number.isNaN(toZoom)) {
							toZoom = state.leaflet.defaultZoom;
						}
						if (toZoom > maxZoom) {
							toZoom = maxZoom;
						} else if (toZoom < minimumZoom) {
							toZoom = minimumZoom;
						}
						state.leaflet.instance.setZoom(toZoom);
					}
				}
				catch (e) {
					console.log("setInstanceGeometry: " + e.message, Date.now() * .001);
				}

				resolve();
			});
		},
		setUIStatus: function (status, timeout) {
			if (state.uiStatusTimeoutHandle) {
				clearTimeout(state.uiStatusTimeoutHandle);
			};

			switch (status) {
				case 'floorPlan':
				case enums.uiStatuses.floorPlan:
					state.uiStatus = enums.uiStatuses.floorPlan;
					state.dom.noFloorPlan.hide();
					state.dom.loadingAnimation.hide();
					state.dom.floorPlanContainer.show();
					esv2ui.floorPlanShown();
					break;
				case 'loading':
				case enums.uiStatuses.loading:
					state.floorPlans.currentFloorPlanId = null;
					state.uiStatus = enums.uiStatuses.loading;
					state.dom.noFloorPlan.hide();
					state.dom.loadingAnimation.show();
					state.dom.floorPlanContainer.hide();
					if (timeout && !isNaN(timeout)) {
						state.uiStatusTimeoutHandle = setTimeout(function () {
							local.setUIStatus(enums.uiStatuses.none);
						}, timeout);
					}
					break;
				case 'none':
				case enums.uiStatuses.none:
					state.floorPlans.currentFloorPlanId = null;
					state.uiStatus = enums.uiStatuses.none;
					state.dom.noFloorPlan.show();
					state.dom.loadingAnimation.hide();
					state.dom.floorPlanContainer.hide();
					break;
			};
			return Promise.resolve(true);
		},
		updateClient: function (clientInstanceId) {
			return new Promise(function (resolve, reject) {
				if (state.clients.locks[clientInstanceId] && state.clients.locks[clientInstanceId] > Date.now()) {
					return resolve(); // Nothing more to do - already running.
				}
				state.clients.locks[clientInstanceId] = Date.now() + 1000;

				var appState = state.configuration.applicationState;
				var client;
				var doCenterOnSelf = false;
				var doRender = false;
				var isSelf = false;

				// First, retrieve the client indicated by the clientInstanceId
				if (clientInstanceId == appState.telemetrySelf.clientInstanceId) {
					client = appState.telemetrySelf;
					isSelf = true;
				} else if (clientInstanceId == appState.self.clientInstanceId) {
					// Handle unexpected cases where telemetrySelf isn't defined.
					client = appState.self;
					isSelf = true;
				} else if (clientInstanceId == 'ella') {
					client = appState.ella;
				} else {
					client = appState.clientInstances.get(clientInstanceId);
				}

				// Now that we have the client retrieved, update the marker
				if (!client || !client.connected) {
					if (state.clients.markers.has(clientInstanceId)) {
						state.clients.markers.delete(clientInstanceId);
					}
				} else {
					var clientMarker = state.clients.markers.get(clientInstanceId);
					if (clientMarker) {
						if (clientMarker.contentPath != client.content) {
							clientMarker.contentPath = client.content;
							if (isSelf) {
								doCenterOnSelf = true; // When the "You" marker moves, center the view on that marker.
							}
							doRender = true;
						}

						if (clientMarker.clientName != client.username) {
							clientMarker.clientName = client.username;
							doRender = true;
						}

						// Setting the heading of a client doesn't require a whole map redraw, so no dirty flag for just that.
						// In fact, we _only_ want to do it here if the render function is _not_ going to be called, so that
						// we avoid a bug where the heading moves before the location does.
						if (!doRender) {
							clientMarker.setHeading(client.heading);
						}
					} else {
						// Adding client marker
						clientMarker = EveryScape.SyncV2.FloorPlanViewer.ClientMarker(
							clientInstanceId,
							client.username,
							client.content,
							client.heading, isSelf
						);
						state.clients.markers.set(clientInstanceId, clientMarker);
						doRender = true;
						if (isSelf) {
							doCenterOnSelf = true; // When the "You" marker moves, center the view on that marker.
						}
					}
				}

				// Clear the lock
				state.clients.locks[clientInstanceId] = null;

				// Decide whether/what to render
				if (doRender) {
					var nextActionPromise;
					if (doCenterOnSelf) {
						nextActionPromise = local.centerOnClient(clientInstanceId, true)
							.then(function (latLngPosition) {
								if (!latLngPosition) {
									events.emit("SelfLocationNotFound");
								}
								return Promise.resolve();
							})
							.finally(local.renderFloorPlan);
					} else {
						nextActionPromise = local.renderFloorPlan();
					}
					nextActionPromise.catch(function (reason) {
						console.log('FloorPlan.updateClient: Error encountered during render:' + reason, Date.now() * .001);
					})
						.finally(resolve);
				} else {
					return resolve();
				}
			});
		},
		updateFloorPlan: function (floorPlanId) {
			if (!floorPlanId || floorPlanId != state.floorPlans.currentFloorPlanId) {
				return Promise.resolve();
			} else {
				return new Promise(function (resolve, reject) {
					local.generateFloorPlan(floorPlanId)
						.then(function (returnValue) {
							events.emit('FloorPlanUpdated', returnValue);
							resolve(returnValue);
						})
						.catch(function (e) {
							reject(e);
						});
				});
			}
		}
	};

	// Initialize
	(function () {
		// Initialize Configuration
		if (!config) {
			config = {};
		}

		// Container
		if (!config.container) {
			state.dom.outerContainer = document.getElementById('FloorPlanContainer');
		} else {
			if (config.container.tagName && config.container.id) {
				state.dom.outerContainer = config.container;
			} else {
				state.dom.outerContainer = document.getElementById(config.container);
			}
		}

		// ApplicationState
		if (config.applicationState) {
			state.configuration.applicationState = config.applicationState;
		} else {
			state.configuration.applicationState = esv2CurrentState;
		}

		if (config.loadingImageUrl) {
			state.configuration.loadingImageUrl = config.loadingImageUrl;
		} else {
			state.configuration.loadingImageUrl = 'https://app.infinityy.com/content/Images/infinityy/infinityy-loading4.gif';
		}

		// Floor plan Descriptor(s)
		var initializeFloorPlanList = new Promise(function (resolve, reject) {
			if (config.initialFloorPlanList) {
				var floorPlanListPromises = [];
				config.initialFloorPlanList.forEach(function (descriptor) {
					if (descriptor) {
						floorPlanListPromises.push(local.addFloorPlanDescriptor(descriptor));
					}
				});

				// Set the current floor plan, so that later steps work
				if (config.initialFloorPlanList[0] && config.initialFloorPlanList[0].id) {
					Promise.all(floorPlanListPromises).then(function () {
						local.selectFloorPlan(config.initialFloorPlanList[0].id).then(resolve);
					});
				} else {
					resolve();
				}

			} else {
				resolve();
			}
		});

		// Set up DOM elements
		var loading = document.createElement('DIV');
		loading.setAttribute("id", EveryScape.SyncV2.FloorPlanViewerUtils.guid());
		loading.style.width = '100%';
		loading.style.height = '100%';
		var image = document.createElement('IMG');
		image.src = state.configuration.loadingImageUrl;
		image.style.objectFit = 'contain';
		image.style.height = '80px';
		image.style.width = '80px';
		image.style.position = 'relative';
		image.style.top = '50%';
		image.style.left = '50%';
		image.style.transform = 'translate(-50%, -50%)';
		loading.appendChild(image);
		loading.classList.add("floor-plan-loading");
		state.dom.loadingAnimation = loading;
		state.dom.outerContainer.appendChild(loading);
		loading.show = function () { loading.style.display = 'block'; };
		loading.hide = function () { loading.style.display = 'none'; };

		var noFloorPlan = document.createElement('DIV');
		noFloorPlan.setAttribute("id", EveryScape.SyncV2.FloorPlanViewerUtils.guid());
		noFloorPlan.innerHTML = "<p>No Floor Plan Available</p>";
		noFloorPlan.classList.add("floor-plan-notfound");
		state.dom.noFloorPlan = noFloorPlan;
		state.dom.outerContainer.appendChild(noFloorPlan);
		let $FloorPlanTab = $("#FloorPlanTab");
		let $FloorPlanSection = $("#FloorPlanSection")
		noFloorPlan.show = function () {
			noFloorPlan.style.display = 'flex';
			$FloorPlanTab.hide();
			$FloorPlanSection.addClass("hidden");
			if ($("#SpaceInfoTab").hasClass("hidden")) {
				//if there is no floor plan tab and no info tab, then we don't need to show the tab interface.
				$("#ContentStripAndFloorPlanMenu").addClass("noFloorPlan");
			}
		};
		noFloorPlan.hide = function () {
			noFloorPlan.style.display = 'none';
			$FloorPlanTab.show();
			$FloorPlanSection.removeClass("hidden");
			$("#ContentStripAndFloorPlanMenu").removeClass("noFloorPlan");
		};


		var fp = document.createElement('DIV');
		fp.setAttribute("id", EveryScape.SyncV2.FloorPlanViewerUtils.guid());
		fp.style.width = '100%';
		fp.style.height = '100%';
		fp.show = function () { fp.style.display = "flex"; };
		fp.hide = function () { fp.style.display = "none"; };
		state.dom.floorPlanContainer = fp;
		state.dom.outerContainer.appendChild(fp);

		// Instantiate Leaflet engine
		var initializeLeaflet = new Promise(function (resolve, reject) {
			let leafletMapOptions = {
				attributionControl: false,
				crs: L.CRS.Simple,
				maxBoundsViscosity: 1.0
			};
			// Animations in leaflet seem to create a problem on apple devices by resulting in an enormous number of
			// "wakeup" events - which causes iOS devices to kill a process.
			// So, for iOS devices, and for now Macs and Android as well (to be safe), disable leaflet animations.
			if (navigator && navigator.platform && /(Mac|iPhone|iPod|iPad|Android)/i.test(navigator.platform)) {
				leafletMapOptions.zoomAnimation = false;
				leafletMapOptions.fadeAnimation = false;
				leafletMapOptions.markerZoomAnimation = false;
			}

			state.leaflet.instance = L.map(state.dom.floorPlanContainer.id, leafletMapOptions);

			state.leaflet.layers.markers = new L.layerGroup()
				.addTo(state.leaflet.instance);

			state.leaflet.instance.on('load', function () {
				state.isLoaded = true;
				events.emit('Loaded', state.leaflet.instance);
				events.emit('Shown');
			});

			events.on("Shown", function () {
				state.leaflet.instance.invalidateSize();
				if (state.uiStatus == enums.uiStatuses.floorPlan) {
					local.setInstanceGeometry();
				}
			})

			state.leaflet.instance.on('viewreset', () => {
				local.renderFloorPlan();
				// On changing floor plans, reset the instance geometry.
				// Something is not setting it properly initially, but this updates it on floor plan change at least.
				// TODO: track this issue down and stop requiring these geometry invalidations.
				state.leaflet.instance.invalidateSize();
				local.setInstanceGeometry();
			});
			return resolve();
		});


		// Wire up client events
		var initializeClientEvents = new Promise(function (resolve, reject) {
			var appState = state.configuration.applicationState;

			var handleClientChange = function (client) {
				return local.updateClient(client.clientInstanceId);
			}

			// When clients change (telemetrySelf + other clients), update their marker, and set their state now.
			appState.telemetrySelf.onChange = handleClientChange;
			appState.clientInstances.list().forEach(function (client) {
				client.onChange = handleClientChange;
				handleClientChange(client);
			});

			// Detect when clients are added or removed.
			appState.clientInstances.onRemove = handleClientChange;
			appState.clientInstances.onAdd = function (client) {
				client.onChange = handleClientChange;
				handleClientChange(client.clientInstanceId);
			};

			// Run the update for telemetrySelf now.
			handleClientChange(appState.telemetrySelf).then(resolve);
		});

		Promise.all([initializeFloorPlanList, initializeLeaflet, initializeClientEvents]).then(function () {
			local.centerOnClient(state.configuration.applicationState.telemetrySelf.clientInstanceId, false);
		});
	})();


	// Define public methods
	var pub = {
		addFloorPlanDescriptor: function (descriptor) {
			return local.addFloorPlanDescriptor(descriptor);
		},
		addFloorPlanDescriptors: function (descriptorList) {
			return local.addFloorPlanDescriptors(descriptorList);
		},
		centerOnClient: function (clientId, animate) {
			return local.centerOnClient(clientId, animate);
		},
		centerOnLocation: function (contentId, animate) {
			return local.centerOnLocation(contentId, animate);
		},
		getDescriptorsByContentPath: function (contentPath) {
			return local.getDescriptorsByContentPath(contentPath);
		},
		hasFloorPlanDescriptor: function (floorPlanId) {
			return local.hasFloorPlanDescriptor(floorPlanId);
		},
		selectFloorPlan: function (floorPlanId) {
			return local.selectFloorPlan(floorPlanId);
		},
		showEmptyView: function () {
			return local.setUIStatus(enums.uiStatuses.none);
		},
		showFloorPlanView: function () {
			return local.setUIStatus(enums.uiStatuses.floorPlan);
		},
		showLoadingView: function (timeout) {
			return local.setUIStatus(enums.uiStatuses.loading, timeout);
		},
		updateClient: function (clientInstanceId) {
			return local.updateClient(clientInstanceId);
		},
		updateFloorPlan: function (floorPlanId) {
			return local.updateFloorPlan(floorPlanId);
		}
	};

	// Define public properties
	Object.defineProperties(pub, {
		applicationState: {
			get: function () {
				return state.configuration.applicationState;
			},
			set: function (val) {
				if (val) {
					state.configuration.applicationState = val;
				}
				return state.configuration.applicationState;
			}
		},
		currentDescriptor: {
			get: function () {
				return state.floorPlans.currentFloorPlanId;
			}
		},
		descriptors: {
			get: function () {
				return state.floorPlans.collection;
			}
		},
		events: {
			get: function () {
				return events;
			}
		}
	});

	// Pass public interface back to invoker
	return pub;
}

EveryScape.SyncV2.FloorPlanViewer.Version = 1.001;

EveryScape.SyncV2.FloorPlanViewer.ContentMarker = function (contentPath, name, x, y, floorPlanHeading, displayIcon) {
	var id = contentPath.replace(/[^0-9A-Za-z\-_]+/g, '-');
	var classNames = 'everyscape-floor-plan-content everyscape-floor-plan-content-' + id;
	switch (contentPath.split(':')[0]) {
		case 'everyscape':
		case 'matterport':
		case 'streetview':
			classNames += ' panoramic-content';
			break;
	}
	switch (displayIcon) {
		case 'default':
			classNames += ' display-icon-default';
			break;
		default:
			classNames += ' display-icon-none';
			break;
	}
	var events = EveryScape.Events.CreateManager();
	var icon = L.divIcon({
		className: classNames,
		html: '<div class="everyscape-floor-plan-content-overlay"></div><div class="everyscape-floor-plan-content-clients"></div>',
		iconSize: [20, 20]
	});
	var marker = L.marker([y, x], { icon: icon, title: name, alt: 'point: ' + name, zIndexOffset: 1000 });

	marker.on('click', function () {
		events.emit('Selected');
	});

	var pub = {
		contentPath: contentPath,
		getDiv: function () {
			return jQuery('.everyscape-floor-plan-content-' + id)[0];
		},
		getMarker: function () { return marker; },
		getClientsDiv: function () { return $('.everyscape-floor-plan-content-' + id + ' .everyscape-floor-plan-content-clients')[0]; },
		id: id,
		floorPlanHeading: floorPlanHeading,
		clients: {},
		updateOverlay: function () {
			// initialize relevant variables
			var $cm = jQuery('.everyscape-floor-plan-content-' + id);
			var $pd = jQuery('.everyscape-floor-plan-content-' + id + ' .everyscape-floor-plan-content-clients');
			var $o = jQuery('.everyscape-floor-plan-content-' + id + ' .everyscape-floor-plan-content-overlay');
			var count = $pd.find('.everyscape-floor-plan-client').length;
			var hasSelf = $pd.find('.everyscape-floor-plan-self-client').length > 0 ? true : false;

			// Set the base rotation for the clients div
			$pd.css({ transform: 'rotate(' + (floorPlanHeading) + 'deg)' });

			// Determine which clients need to be represented, and update labels accordingly.
			if (count <= 0) {
				$o.html('');
				$o.removeClass('everyscape-floor-plan-content-overlay-show');
				$o.removeClass('everyscape-floor-plan-content-overlay-self');
			} else {
				$o.addClass('everyscape-floor-plan-content-overlay-show');
				if (hasSelf) {
					$o.addClass('everyscape-floor-plan-content-overlay-self');
					var you = 'You';
					if (count > 1) {
						you += '+' + (count - 1);
					}
					$o.html(you);
				} else {
					$o.removeClass('everyscape-floor-plan-content-overlay-self');
					$o.html(count);
				}
			}
			var newTitle = name;
			for (var clientInstanceId in pub.clients) {
				newTitle += "\n" + pub.clients[clientInstanceId].clientName;
			}
			$cm.attr('title', newTitle);
		}
	};

	Object.defineProperty(pub, 'events', {
		get: function () {
			return events;
		}
	});

	return pub;
};

EveryScape.SyncV2.FloorPlanViewer.ClientMarker = function (clientInstanceId, clientName, contentPath, heading, isSelf) {
	var marker = document.createElement('DIV');
	var $cm = jQuery(marker);
	$cm.attr('id', 'everyscape-floor-plan-client-' + clientInstanceId);
	$cm.addClass('everyscape-floor-plan-client');
	if (isSelf) {
		$cm.addClass('everyscape-floor-plan-self-client');
	}

	var doSetHeading = function (newHeading) {
		if (newHeading == null || isNaN(newHeading)) {
			$cm.css({ transform: 'rotate(0deg)' });
			$cm.removeClass('has-heading');
		} else {
			var nh = (newHeading + 3600) % 360;
			$cm.css({ transform: 'rotate(' + nh + 'deg)' });
			$cm.addClass('has-heading');
		}
	}

	var api = {
		getDiv: function () { return marker; },
		contentPath: contentPath,
		clientInstanceId: clientInstanceId,
		clientName: clientName,
		setHeading: function (newHeading) {
			doSetHeading(newHeading);
		}
	};

	return api;
};

EveryScape.SyncV2.FloorPlanViewer.Descriptor = function (config) {
	if (!config) { config = {}; }

	var state = {
		contentPositions: new Map(),
		defaultCenterX: config.defaultCenterX,
		defaultCenterY: config.defaultCenterY,
		defaultZoom: config.defaultZoom,
		id: config.id,
		imageUrl: config.imageUrl,
		name: config.name,
		naturalHeight: config.naturalHeight,
		naturalWidth: config.naturalWidth
	}

	var pub = {
		addPosition: function (position) {
			if (position.contentPath) {
				state.contentPositions.set(position.contentPath, position);
			}
		},
		getPosition: function (contentPath) {
			return state.contentPositions.get(contentPath);
		},
		getPositions: function () {
			return Array.from(state.contentPositions.values());
		},
		getVersion: function () {
			return EveryScape.SyncV2.FloorPlanViewer.Version;
		}
	}

	Object.defineProperties(pub, {
		defaultCenterX: {
			get: function () {
				return state.defaultCenterX;
			},
			set: function (val) {
				state.defaultCenterX = val;
				return state.defaultCenterX;
			}
		},
		defaultCenterY: {
			get: function () {
				return state.defaultCenterY;
			},
			set: function (val) {
				state.defaultCenterY = val;
				return state.defaultCenterY;
			}
		},
		defaultZoom: {
			get: function () {
				return state.defaultZoom;
			},
			set: function (val) {
				state.defaultZoom = val;
				return state.defaultZoom;
			}
		},
		id: {
			get: function () {
				return state.id;
			},
			set: function (val) {
				state.id = val;
				return state.id;
			}
		},
		imageUrl: {
			get: function () {
				return state.imageUrl;
			},
			set: function (val) {
				state.imageUrl = val;
				return state.imageUrl;
			}
		},
		name: {
			get: function () {
				return state.name;
			},
			set: function (val) {
				state.name = val;
				return state.name;
			}
		},
		naturalHeight: {
			get: function () {
				return state.naturalHeight;
			},
			set: function (val) {
				state.naturalHeight = val;
				return state.naturalHeight;
			}
		},
		naturalWidth: {
			get: function () {
				return state.naturalWidth;
			},
			set: function (val) {
				state.naturalWidth = val;
				return state.naturalWidth;
			}
		}
	});

	return pub;
}

EveryScape.SyncV2.FloorPlanViewer.Descriptor.Position = function (config) {
	if (!config) { config = {}; }
	var state = {
		contentPath: config.contentPath,
		contentType: config.contentType,
		displayIcon: config.displayIcon,
		heading: config.heading,
		name: config.name,
		x: config.x,
		y: config.y,
	}

	var pub = {}

	Object.defineProperties(pub, {
		contentPath: {
			get: function () {
				return state.contentPath;
			},
			set: function (val) {
				state.contentPath = val;
				return val;
			}
		},
		contentType: {
			get: function () {
				return state.contentType;
			},
			set: function (val) {
				state.contentType = val;
				return val;
			}
		},
		displayIcon: {
			get: function () {
				if (state.displayIcon === null) {
					state.displayIcon = 'none';
				}
				return state.displayIcon;
			},
			set: function (val) {
				switch (val) {
					case 'none':
						state.displayIcon = 'none';
						break;
					case 'default':
						state.displayIcon = 'default';
						break;
					default:
						state.displayIcon = null;
						break;
				}
				return state.displayIcon;
			}
		},
		id: {
			get: function () {
				return state.id;
			},
			set: function (val) {
				state.id = val;
				return val;
			}
		},
		heading: {
			get: function () {
				return state.heading;
			},
			set: function (val) {
				state.heading = val;
				return val;
			}
		},
		name: {
			get: function () {
				return state.name;
			},
			set: function (val) {
				state.name = val;
				return val;
			}
		},
		x: {
			get: function () {
				return state.x;
			},
			set: function (val) {
				state.x = val;
				return val;
			}
		},
		y: {
			get: function () {
				return state.y;
			},
			set: function (val) {
				state.y = val;
				return val;
			}
		}
	});

	return pub;
}
