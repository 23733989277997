let streetviewService = null;

function getBearing(targetLat, targetLng, originLat, originLng) {
	targetLat = toRadians(targetLat);
	targetLng = toRadians(targetLng);
	originLat = toRadians(originLat);
	originLng = toRadians(originLng);

	const y = Math.sin(targetLng - originLng) * Math.cos(targetLat);
	const x = Math.cos(originLat) * Math.sin(targetLat) - Math.sin(originLat) * Math.cos(targetLat) * Math.cos(targetLng - originLng);
	return (toDegrees(Math.atan2(y, x)) + 360) % 360;
}

function getNearestStreetViewPanorama(location, radius, source) {
	location = normalizeLatLng(location);
	if (!location) {
		return Promise.reject("Invalid Location");
	} else {
		return new Promise(function (resolve) {
			if (!radius) { radius = 500; }
			if (!source) { source = google.maps.StreetViewSource.OUTDOOR; } // Only other option is google.maps.StreetViewSource.DEFAULT
			const req = {
				location: location,
				preference: google.maps.StreetViewPreference.NEAREST,
				radius: radius,
				source: source
			};

			streetviewService = streetviewService || new google.maps.StreetViewService();

			streetviewService.getPanorama(req, function (data) {
				if (data && data.location && data.location.pano) {
					const panoLatLng = normalizeLatLng(data.location.latLng);
					const heading = getBearing(location.lat, location.lng, panoLatLng.lat, panoLatLng.lng);
					resolve({
						panoId: data.location.pano,
						heading: heading,
						latLng: panoLatLng
					});
				} else {
					resolve(null);
				}
			});
		});
	}
}

async function getStreetView(location, radius, source) {
	const result = await getNearestStreetViewPanorama(location, radius, source);
	return getStreetViewFromDescriptor(result);
}

function getStreetViewFromDescriptor(descriptor) {
	if (descriptor && descriptor.panoId) {
		return {
			viewerType: 'streetview',
			panoId: descriptor.panoId,
			heading: descriptor.heading || 0,
			pitch: 0,
			zoom: 1
		};
	} else {
		return null;
	}
}

function getStreetViewStaticMedia(panoramaId, heading, pitch, fov) {
	// sensible defaults
	heading = !isNaN(heading) ? heading : 0;
	pitch = !isNaN(pitch) ? pitch : 0;
	fov = !isNaN(fov) ? fov : 90;

	let svStaticUrl = `${window.siteSettings.GoogleStreetViewImageBaseUrl}?key=${window.GMapsApiKey}&size=300x300&heading=${heading}&pitch=${pitch}&fov=${fov}&pano=${panoramaId}`;

	return svStaticUrl;
}

async function getStreetViewWithPreview(location, radius, source) {
	const result = await getStreetView(location, radius, source);
	if (result) {
		result.previewUrl = getStreetViewStaticMedia(gResult.panoId, gResult.heading, 0, 90);
	}
	return result;
}

function mapPositionsAreEqual(latLng1, latLng2, degreesTolerance) {
	const a = normalizeLatLng(latLng1);
	const b = normalizeLatLng(latLng2);
	degreesTolerance = degreesTolerance ? degreesTolerance : 0.0005; // ~50m on average

	return Math.abs(a.lat - b.lat) <= degreesTolerance &&
		Math.abs(a.lng - b.lng) <= degreesTolerance;
}

function normalizeHeading(h) {
	return isNaN(h) ? 0 : (h % 360);
}

function normalizeLatLng(input) {
	if (!input) return { isValid: false };

	if (input.lat instanceof Function && input.lng instanceof Function) {
		return {
			lat: input.lat(),
			lng: input.lng(),
			isValid: true
		}
	} else if (!isNaN(input.lat) && !isNaN(input.lng)) {
		return {
			lat: input.lat,
			lng: input.lng,
			isValid: true
		};
	} else {
		return { isValid: false };
	}
}

function snapPitch(p) {
	if (isNaN(p)) { return 0; }
	if (p < -90) { return -90; }
	if (p > 90) { return 90; }
	return p;
}

function toDegrees(radians) { return radians * 180 / Math.PI; }

function toRadians(degrees) { return degrees * Math.PI / 180; }

// Streetview pano viewer and streetview image data use different standards
// Here, "zoom" refers to the ~0.5 - 2.0 values used by the pano viewer, and
// "fov" refers to the ~50 - ~110 degree values used by the streetview static image API
function translateStreetviewFovToZoom(fov) {
	try {
		return Math.log(180 / fov) / Math.log(2);
	}
	catch (err) {
		console.error(err);
		return 90;
	}
}

function translateStreetviewZoomToFov(zoom) {
	try {
		return 180 / Math.pow(2, zoom);
	}
	catch (err) {
		console.error(err);
		return 90;
	}
}


export {
	getBearing,
	getNearestStreetViewPanorama,
	getStreetView,
	getStreetViewFromDescriptor,
	getStreetViewStaticMedia,
	getStreetViewWithPreview,
	mapPositionsAreEqual,
	normalizeHeading,
	normalizeLatLng,
	snapPitch,
	toDegrees,
	toRadians,
	translateStreetviewFovToZoom,
	translateStreetviewZoomToFov
}
