import { mapPositionsAreEqual, normalizeLatLng } from "../utils/map-utils.es6";

if ('undefined' === typeof(EveryScape)) { EveryScape = {}; }
if (!EveryScape.SyncV2) { EveryScape.SyncV2 = {}; }
if (typeof (EveryScape) === 'undefined' || EveryScape === null) { EveryScape = {}; }
if (typeof (EveryScape.Sync) === 'undefined' || EveryScape.Sync === null) { EveryScape.Sync = {}; }

//
// TODO: The gmap viewer should be completely separated from page business logic and syncv2 model data and have the following functions:
// - loadMap(coords)
// - setRadars(all the radars for other users and current user)
// - onSelectLocation(contentPath or lat/lng) -> hooked by the controller to update the viewer.
//

EveryScape.SyncV2.GMap = function (config) {
	// Initialize Events Instance
	var events = EveryScape.Events.CreateManager();

	// Initialize State
	var state = {
		container: null,
		defaultZoomLevel: 15,
		geocoder: null,
		map: null,
		mapBounds: null,
		markers: new Map(),
		radarMarkers: new Map(),
		trackToPoint: {
			basis: null,
			basisChanged: false,
			enabled: false
		}
	};

	// Local Methods
	var local = {
		addMarker: function (name, label, location, clickHandler, locationIsLatLng, isBuilding) {
			return new Promise(function (resolve, reject) {
				let marker = null;
				var updateMap = function (position) {
					// Create Marker
					let iconUrl = "/images/noun-map-marker.svg";
					if (isBuilding) {
						iconUrl = "/images/noun-building.svg";

					}
					var marker = new google.maps.Marker({
						label: {
							text: ' ',
							color: "white"
						},
						position: position,
						map: state.map,
						title: name,
						icon: iconUrl
					});

					marker.addListener('click', clickHandler);

					// Add marker to collection, indexed by Label.
					state.markers.set(label, marker);

					return marker;
				}

				if (locationIsLatLng) {
					// Just use the LatLng object or LatLng object literal
					marker = updateMap(location);
					resolve(marker);
				} else {
					// Geolocate based on address
					state.geocoder.geocode({ 'address': location }, function (results, status) {
						if (status === 'OK') {
							marker = updateMap(results[0].geometry.location);
						} else {
							events.emit("Debug", "Geocoding failed with error [" + status + "] for address [" + location + "]");
						}
						resolve(marker);
					});
				}
			});
		},
		getMarkerByLabel: function (label) {
			return state.markers.get(label);
		},
		centerOnPosition: function (position) {
			position = normalizeLatLng(position);
			if (position.isValid) {
				state.map.setCenter(position);
				state.map.setZoom(state.defaultZoomLevel);
			}
		},
		clearMarkers: function () {
			// Note that this only affects normal markers, not radar markers.
			let list = Array.from(state.markers.values());
			list.forEach(i => {
				if (i.setMap) {
					i.setMap(null);
				}
			});
			return Promise.resolve();
		},
		getRadarIcon: function (clientInstanceId, heading) {
			let iconSize = 110;
			let numSprites = 16;
			let offsetX = (Math.round((heading) / Math.round(360 / numSprites)) % numSprites) * iconSize;
			let offsetY = local.isSelf(clientInstanceId) ? 0 : iconSize;
			let i = {
				anchor: new google.maps.Point(Math.round(iconSize / 2), Math.round(iconSize / 2)),
				origin: new google.maps.Point(offsetX, offsetY),
				scaledSize: new google.maps.Size(iconSize * numSprites, iconSize * 2),
				size: new google.maps.Size(iconSize, iconSize),
				url: "/images/radar_sprite_sheet.png"
			};
			return i;
		},
		getRadarMarker: function (clientInstanceId) {
			return state.radarMarkers.get(clientInstanceId);
		},
		getTileset: function () {
			return state.map.getMapTypeId();
		},
		isSelf: function (clientInstanceId) {
			return window.clientInstanceId === clientInstanceId;
		},
		removeMarker: function (marker) {
			marker.setMap(null);
			return Promise.resolve();
		},
		removeRadarMarker: function (clientInstanceId) {
			let m = state.radarMarkers.get(clientInstanceId);
			if (m) {
				state.radarMarkers.delete(clientInstanceId);
				m.setMap(null);
				m = null;
			}
		},
		setRadarMarker: function (clientInstanceId, displayName, lat, lng, heading) {
			const newPosition = { lat: lat, lng: lng };
			let positionChanged = false;
			let m = state.radarMarkers.get(clientInstanceId);
			if (m) {
				const oldPosition = m.getPosition();
				positionChanged = !mapPositionsAreEqual(oldPosition, newPosition, 0);

				m.setIcon(local.getRadarIcon(clientInstanceId, heading));
				if (positionChanged) {
					m.setPosition(newPosition);
				}
				m.setTitle(displayName);
			} else {
				positionChanged = true;
				m = new google.maps.Marker({
					icon: local.getRadarIcon(clientInstanceId, heading),
					map: state.map,
					optimized: false,
					position: new google.maps.LatLng({ lat: lat, lng: lng }),
					shape: { coords: [36, 36, 36], type: 'circle' },
					title: displayName
				});
				state.radarMarkers.set(clientInstanceId, m);
			}

			const trackingAvailable = local.isSelf(clientInstanceId) && state.trackToPoint.enabled && state.trackToPoint.basis ? true : false;
			if (trackingAvailable && (positionChanged || state.trackToPoint.basisChanged)) {
				local.trackToPoint(newPosition);
			}
		},
		fitMarkersBounds: function() {
			const bounds = new google.maps.LatLngBounds();
			const list = Array.from(state.markers.values());
			list.forEach(m => bounds.extend(m.getPosition()));

			state.mapBounds = bounds;
			state.map.fitBounds(bounds, 40);
		},
		setTileset: function (type) {
			type = type.toLowerCase();
			switch (type) {
				case 'roadmap':
				case 'satellite':
					state.map.setMapTypeId(type)
					break;
			}

			return state.map.getMapTypeId();
		},
		setTrackingPoint: function (latLng) {
			latLng = normalizeLatLng(latLng);
			if (latLng.isValid) {
				state.trackToPoint.basis = latLng;
				state.trackToPoint.basisChanged = true;
				state.trackToPoint.enabled = true;
				return latLng;
			} else {
				state.trackToPoint.enabled = false;
				return null;
			}
		},
		trackingEnabled: function (val) {
			state.trackToPoint.enabled = !!val;
			return state.trackToPoint.enabled;
		},
		trackToPoint: function (newPosition) {
			if (state.trackToPoint.basisChanged) {
				state.trackToPoint.basisChanged = false;
			}
			const basis = state.trackToPoint.basis;
			if (mapPositionsAreEqual(basis, newPosition)) {
				local.centerOnPosition(basis);
			} else {
				const newMapBounds = new google.maps.LatLngBounds();
				newMapBounds.extend(basis);
				newMapBounds.extend(newPosition);
				state.mapBounds = newMapBounds;
				state.map.fitBounds(newMapBounds, 40);
			}
		}
	};

	// Initialize
	(function () {
		// Check configuration
		if (!config) {
			config = {};
		}

		// Container
		if (!config.container) {
			state.container = document.getElementById('OverviewMapContainer');
		} else {
			if (config.container.tagName && config.container.id) {
				state.container = config.container;
			} else {
				state.container = document.getElementById(config.container);
			}
		}

		if (!config.tileset) {
			config.tileset = 'satellite';
		}

		// Initialize the map
		state.map = new google.maps.Map(state.container, {
			center: { lat: 0, lng: 0 },
			disableDefaultUI: true,
			disableDoubleClickZoom: true,
			mapTypeControl: true,
			mapTypeControlOptions: {
				mapTypeIds: ['roadmap', 'satellite'],
				position: google.maps.ControlPosition.TOP_LEFT
			},
			mapTypeId: config.tileset,
			//draggableCursor:'default',
			//gestureHandling: "none",
			maxZoom: 18,
			zoom: state.defaultZoomLevel
		});

		state.mapBounds = new google.maps.LatLngBounds();

		state.geocoder = new google.maps.Geocoder();

		// Events wireup
		state.map.addListener('center_changed', function () {
			events.emit('center_changed', state.map.getCenter());
		});
		state.map.addListener('heading_changed', function () {
			events.emit('heading_changed', state.map.getHeading());
		});
		state.map.addListener('zoom_changed', function () {
			events.emit('zoom_changed', state.map.getZoom());
		});
		state.map.addListener('dblclick', function (mouseEvent) {
			events.emit('dblclick', mouseEvent.latLng);
		});
		state.map.addListener('click', function (mouseEvent) {
			events.emit('click', mouseEvent.latLng);
		});
	})();

	// Public Methods
	var pub = {
		addMarker: function (name, label, location, clickHandler, locationIsLatLng, isBuilding) {
			return local.addMarker(name, label, location, clickHandler, locationIsLatLng, isBuilding);
		},
		getMarkerByLabel: function(label) {
			return local.getMarkerByLabel(label);
		},
		centerOnPosition: function (latLng) {
			return local.centerOnPosition(latLng);
		},
		clearMarkers: function () {
			return local.clearMarkers();
		},
		removeMarker: function (marker) {
			return local.removeMarker(marker);
		},
		getRadarMarker: function (clientInstanceId) {
			return local.getRadarMarker(clientInstanceId);
		},
		removeRadarMarker: function (clientInstanceId) {
			return local.removeRadarMarker(clientInstanceId);
		},
		setRadarMarker: function (clientInstanceId, displayName, lat, lng, heading, doCenter) {
			return local.setRadarMarker(clientInstanceId, displayName, lat, lng, heading, doCenter);
		},
		setTrackingPoint: function (latLng) {
			return local.setTrackingPoint(latLng);
		},
		fitMarkersBounds: function() {
			return local.fitMarkersBounds();
		}
	};

	Object.defineProperties(pub, {
		events: {
			get: function () {
				return events;
			}
		},
		map: {
			get: function () {
				return state.map;
			}
		},
		mapBounds: {
			get: function () {
				return state.mapBounds;
			}
		},
		markers: {
			get: function () {
				return state.markers;
			}
		},
		radarMarkers: {
			get: function () {
				return state.radarMarkers;
			}
		},
		tileset: {
			get: function () {
				return local.getTileset();
			},
			set: function (type) {
				return local.setTileset(type);
			}
		},
		trackingEnabled: {
			get: function () {
				return state.trackToPoint.enabled;
			},
			set: function (val) {
				return local.trackingEnabled(val);
			}
		}
	})

	return pub;
};
